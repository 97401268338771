import React from "react"

const PageContentContainer = ({ children, className = "" }) => {
  return (
    <div className={`bg-topograpyLight ${className} flex-grow flex flex-col`}>
      {children}
    </div>
  )
}

export default PageContentContainer
