import React from "react"

const BannerContainer = ({ color = "", children, className = "", id = "" }) => {
  return (
    <div
      className={` ${color} bg-opacity-90 flex-grow flex flex-col items-center ${className}`}
      id={id}
    >
      {children}
    </div>
  )
}

export default BannerContainer
