import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import ImageGallery from "react-image-gallery"
import urlBuilder from "@sanity/image-url"
import { mySanityClient } from "../../sanityClient"
import SEO from "../../components/SEO"
import PageContentContainer from "../../components/PageContentContainer"
import BannerContainer from "../../components/bannerContainer"
import BannerTitleNew from "../../components/BannerTitleNew"
import WaveDivider from "../../components/svgs/WaveDivider"
import ButtonLink from "../../components/ButtonLink"

const Album = ({ data }) => {
  const { title, description, rawAlbumImages } = data.sanityAlbums
  const builder = urlBuilder(mySanityClient)
  // Get the url for a given source
  const urlFor = source => builder.image(source)

  // create an array of urls for the album
  const imageUrlArray = rawAlbumImages.map(imageData => {
    const url = urlFor(imageData.asset.id)
      .width(1000)
      .format("jpg")
      .fit("max")
      .url()

    const previewUrl = urlFor(imageData.asset.id)
      .width(200)
      .height(200)
      .format("jpg")
      .url()

    const altText = imageData.altText || "Alt Text Not Provided"
    const imageTitle = imageData.imageTitle || ""
    return {
      original: url,
      originalAlt: altText,
      thumbnail: previewUrl,
      thumbnailAlt: altText,
      description: imageTitle,
    }
  })

  return (
    <Layout className="text-center justify-items-center">
      <SEO title={title} />
      <PageContentContainer>
        <BannerContainer color="bg-primary" className="py-20">
          <BannerTitleNew
            className="mb-5"
            props={{
              divider: (
                <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40 px-5" />
              ),

              doubleDivider: true,
              text: (
                <div className="grid justify-items-center">
                  <h1 className="text-5xl md:text-6xl font-semibold text-center">
                    {title}
                  </h1>
                  <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                </div>
              ),
            }}
          />

          {description && <p className="text-xl">{description}</p>}

          <div className="my-10 px-5 w-full max-w-6xl">
            <ImageGallery
              items={imageUrlArray}
              thumbnailPosition="top"
              lazyLoad
            />
          </div>
          <ButtonLink
            text="All Albums"
            className="px-6 py-1 text-xl md:text-2xl uppercase tracking-wide"
            to="/gallery"
          />
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityAlbums(id: { eq: $id }) {
      title
      description
      rawAlbumImages: _rawAlbumImages(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default Album
